/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {authenticate, changePassword} from "../../../services/Authenticate";

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})

const initialValues = {
    email: 'admin@demo.com',
    password: 'demo',
    confirm_password: ''
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const [loading, setLoading] = useState(false)
    const [isFirstLogin, setFirstLogin] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const [session, setSession] = useState("")
    const [message, setMessage] = useState("")

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)

            if(isFirstLogin) {
                changePassword(values.email, values.password, session)
                    .then((data) => {
                        setFirstLogin(false)
                        setSubmitting(false)
                        setLoading(false)
                        formik.setFieldValue("password", "")
                    }, (err) => {
                        saveAuth(undefined)
                        setSubmitting(false)
                        setLoading(false)
                        formik.setFieldValue("password", "")
                        setMessage(err.message)
                    })
            } else {
                // cognito
                authenticate(values.email, values.password)
                    .then((data) => {
                        setMessage("")

                        if (data.isFirstLogin) {
                            setFirstLogin(true)
                            setSubmitting(false)
                            setLoading(false)
                            formik.setFieldValue("password", "")
                            setSession(data.Session)
                        } else {
                            // saveAuth({
                            //     api_token: data.accessToken.jwtToken,
                            //     refreshToken: data.refreshToken.token
                            // })

                            setCurrentUser(data.idToken.payload.email)
                        }
                    }, (err) => {
                        saveAuth(undefined)
                        setSubmitting(false)
                        setLoading(false)
                        setMessage(err.message)
                    })
            }
        },
    })

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
                <div className='text-gray-500 fw-semibold fs-6'>Alairo Config GUI</div>
            </div>
            {/* begin::Heading */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {isFirstLogin && (
                <div className='fv-row mb-3'>
                    <label className='form-label text-dark fs-6 mb-0'>This is the first time you are logging in. Please
                        set a new password</label>
                </div>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {message && (
                <div className='fv-row mb-3'>
                    <label className='form-label text-dark fs-6 mb-0'><>{message ? message : ""}</></label>
                </div>
            )}

            {/* begin::Action */}
            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>{isFirstLogin ? "Set Password" : "Login"}</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>
            </div>
            {/* end::Action */}
        </form>
    )
}
