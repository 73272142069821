import { AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import userpool from "../../userpool";


export const changePassword=(Email, Password, Session) => {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: Email,
            Pool: userpool
        })
        user.Session = Session
        user.completeNewPasswordChallenge(Password, {}, {
            onSuccess:(result) => {
                resolve({
                    isFirstLogin: true,
                    user: user
                })
            },
            onFailure:(err) => {
                reject(err)
            }
        })
    })
}

export const authenticate=(Email, Password) => {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: Email,
            Pool: userpool
        })

        const authDetails = new AuthenticationDetails({
            Username: Email,
            Password
        })

        user.authenticateUser(authDetails, {
            onSuccess:(result)=>{
                resolve(result)
            },
            onFailure:(err) => {
                reject(err)
            },
            newPasswordRequired: (userAttr) => {
                resolve({
                    isFirstLogin: true,
                    user: user,
                    userAttr: userAttr,
                    Session: user.Session
                })
            },
        })
    })
}

export const logout = () => {
    const user = userpool.getCurrentUser()
    user.signOut()
    window.location.href = "/"
}